import React from 'react';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { setCookie } from 'nookies';

import { useTranslation } from 'next-i18next';

import Icon from 'src/components/ui/Icon';
import Typography from 'src/components/ui/Typography';

import { useUpdateUserLocaleMutation } from 'src/hooks/__generated__/queries';
import useUser from 'src/hooks/useUser';
import { SupportedLocales } from 'src/types/__generated__/graphql';
import useAppRouter from 'src/hooks/useAppRouter';
import { styledTag } from 'src/utils/styled';
import { AnalyticsEvent } from 'src/utils/analytics';
import useDynamicLogEvents from 'src/hooks/useDynamicLogEvents';

const Container = styledTag('div')`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
`;

const LanguageToggler: React.FC = () => {
    const { t, i18n } = useTranslation(['common']);
    const currentUser = useUser();
    const [updateUserLocale] = useUpdateUserLocaleMutation();
    const router = useAppRouter();
    const { logEventDynamic } = useDynamicLogEvents();
    const toggleTranslate = async () => {
        setCookie(null, 'NEXT_LOCALE', i18n.language === 'en' ? 'fr' : 'en');
        if (currentUser) {
            const newLanguage = i18n.language === 'en' ? SupportedLocales.Fr : SupportedLocales.En;
            await updateUserLocale({
                variables: {
                    locale: newLanguage,
                },
                refetchQueries: ['Notifications'],
            });
        }
        logEventDynamic(AnalyticsEvent.ChangeLanguage);
    };

    return (
        <Container onClick={toggleTranslate}>
            <Link
                href={router.asPath}
                locale={router.locale === 'fr' ? 'en' : 'fr'}
                hrefLang={router.locale === 'fr' ? 'en' : 'fr-FR'}
            >
                <Box display="flex">
                    <Typography variant="caption" color="primary">
                        {t(`common:languages.${i18n.language}`)}
                    </Typography>
                    <Box ml={1} display="flex" alignItems="center">
                        {i18n.language === 'fr' ? (
                            <Icon name="FlagFrance" size="1.125rem" />
                        ) : (
                            <Icon name="FlagUnitedKingdom" size="1.125rem" />
                        )}
                        <Icon name="Caret" size="0.8rem" style={{ marginLeft: 5 }} />
                    </Box>
                </Box>
            </Link>
        </Container>
    );
};

export default LanguageToggler;
