export const noImagePath = `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/no-image.png`;
export const noLinkImagePath = `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/link-picture.jpg`;
export const noAdImagePath = `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/ad-picture.jpg`;
export const noProjectImagePath = `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/project-picture.jpg`;
export const noNewsPostImagePath = `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/news-picture.jpg`;
export const defaultWoopenImagePath = `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/default_image.png`;
export const defaultAudioImagePath = `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/default_audio.jpg`;
export const estimateImagePath = `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/estimate.jpg`;
export const brokenVideo = `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/broken-video.webp`;
export const transcodingVideo = `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/transcoding-video.webp`;
export const brokenPendingVideo = `${process.env.NEXT_PUBLIC_SITE_URL}/assets/images/broken-pending-video.webp`;
