import React, { useState, useEffect } from 'react';

import type { ImageProps } from 'next/image';
import NextImage from 'next/image';

import { noImagePath } from 'src/constants/defaultImages';

const Image: React.FC<ImageProps & { shouldUseImgTag?: boolean }> = ({
    shouldUseImgTag = false, // needed when we want to display images from external source
    alt,
    ...props
}) => {
    const [src, setSrc] = useState<ImageProps['src']>(props.src);

    useEffect(() => {
        setSrc(props.src);
    }, [props.src]);

    const onError = () => setSrc(noImagePath);
    return shouldUseImgTag ? (
        <img
            {...props}
            src={src as string}
            alt={alt}
            onError={onError}
            width={props.fill ? undefined : props.width ?? 500}
            height={props.fill ? undefined : props.height ?? 500}
        />
    ) : (
        <NextImage
            {...props}
            src={src}
            alt={alt}
            onError={onError}
            width={props.fill ? undefined : props.width ?? 500}
            height={props.fill ? undefined : props.height ?? 500}
        />
    );
};

export default Image;
