import { useRouter } from 'next/router';

import type { PropsWithChildren } from 'react';

import React, { useContext, useMemo } from 'react';

import { logEvent } from 'src/utils/firebase';
import type { LogEventDynamicParamKeys } from 'src/utils/analytics';
import { getAnalyticsParams } from 'src/utils/analytics';

const shouldLog = process.env.NODE_ENV !== 'production';

export const AnalyticsContext = React.createContext<Record<string, string | undefined>>({});

type AnalyticsContextProviderType = React.FC<PropsWithChildren<{ value: Record<string, string | undefined> }>>;

export const AnalyticsContextProvider: AnalyticsContextProviderType = ({ value, children }) => {
    const parentContext = useAnalyticsContext();
    const memoizedValue = useMemo(() => ({ ...parentContext, ...value }), [parentContext, value]);
    return <AnalyticsContext.Provider value={memoizedValue}>{children}</AnalyticsContext.Provider>;
};

const useAnalyticsContext = () => {
    const context = useContext(AnalyticsContext);
    return context;
};

const useDynamicLogEvents = () => {
    const { pathname } = useRouter();
    const analyticsContext = useAnalyticsContext();
    const { eventNamePrefix, ...specificParams } = analyticsContext;

    const logEventDynamic = (
        eventNameBase: string,
        eventParams?: Record<string, string | null | undefined | boolean> | LogEventDynamicParamKeys,
    ) => {
        const { eventName, ...params } = getAnalyticsParams(
            eventNameBase,
            pathname,
            eventNamePrefix ? String(eventNamePrefix) : undefined,
        );

        if (shouldLog) {
            console.log('logEventDynamic', eventName, {
                ...params.params,
                ...specificParams,
                ...eventParams,
                debugPathname: pathname,
            });
        }

        logEvent(eventName, {
            ...params.params,
            ...specificParams,
            ...eventParams,
            debugPathname: pathname,
        });
    };

    return { logEventDynamic };
};

export default useDynamicLogEvents;
